.rsbs {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.4);
  --rsbs-bg: #fff;
  --rsbs-max-w: 28rem;
  overflow: hidden !important;
}

.dark .rsbs {
  --rsbs-bg: #1f2937;
}

[data-rsbs-backdrop] {
  background-color: var(--rsbs-backdrop-bg) !important;
  z-index: 400 !important;
  pointer-events: none !important;
}

[data-rsbs-overlay] {
  z-index: 401 !important;
  border-top-left-radius: 35px !important;
  border-top-right-radius: 35px !important;
  background: #ffffff !important;
  max-width: 28rem !important;
  position: fixed !important;
  width: 100% !important;
  inset-inline-end: 0 !important;
  bottom: 0 !important;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.5) !important;
  margin-inline-start: auto !important;
  max-height: 100vh !important;
  overflow: hidden !important;
}

.dark [data-rsbs-overlay] {
  background: #1f2937 !important;
}

[data-rsbs-header]:before {
  margin: 0.5rem auto !important;
}

.category-btn {
  background-color: rgb(249 250 251);
}

.dark .category-btn:hover {
  background-color: rgb(55 65 81);
}

[data-rsbs-has-header='false'] [data-rsbs-header] {
  box-shadow: none;
  padding-top: calc(30px + env(safe-area-inset-top));
}

[data-rsbs-header]:before {
  position: absolute;
  content: '';
  display: block;
  height: 4px;
  width: 3rem;
  top: calc(8px + env(safe-area-inset-top));
  left: 50%;
  transform: translateX(-50%) !important;
  border-radius: 2px;
  background-color: #D1D5DB !important;
}

.dark [data-rsbs-header]:before {
  background-color: #d1d5db !important;
}

/* إزالة الحواف الزرقاء في سفاري iOS */
* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

/* تحسين مظهر التحديد للعناصر التفاعلية */
button, 
select, 
input,
a {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}